import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-kic-papers',
  templateUrl: './kic-papers.component.html',
  styleUrls: ['./kic-papers.component.css']
})
export class KicPapersComponent implements OnInit {

  ELEMENT_DATA = null;
  PUBMED_DATA = null;
  
  displayChart: boolean = false;
  requestError: string;
  pubmedID: string | null;
  data: any = null;

  constructor(private http: HttpClient, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.pubmedID = params.get('pubmedID');
    });

    if (!this.pubmedID) {
      this.getData();
    }
    else {
      this.getPubmedData();

    }
  }

  getData() {
    this.fetchJson('https://p3db.ddns.net:5004/api/get_kic_assay_paper_info/')
      .subscribe(
        jsonData => {
          for (let i = 0; i < jsonData['data'].length; i++ )
            {
              let linkName = jsonData['data'][i].PubmedID;
              jsonData['data'][i]['routerLink'] = linkName;
              let pubmedLink = "https://pubmed.ncbi.nlm.nih.gov/" + jsonData['data'][i]['PubmedID'];
              jsonData['data'][i]['link'] = pubmedLink;
            }

          this.ELEMENT_DATA = {
            name: ["Investigator", "Organism", "OrganismID", "PTM", "PubmedID","Title", "Year", "PeptideCounts"],
            display: ["Investigator", "Organism", "Organism ID", "PTM", "Pubmed ID","Title", "Year", "Peptide Counts"],
            data: jsonData['data'],
          };
          // console.log(this.ELEMENT_DATA);
        },
        error => {
          this.requestError = error;
        }
      );
  }
  getPubmedData()
  {
    // deployment version
    // let api_url = environment.BASE_API_URL + 'proteinids_details_for_datasource/' + '?datasource_id=' + this.pubmedID
    
    // production version
    let api_url = "https://p3db.ddns.net:5004/api/" + 'proteinids_details_for_datasource/' + '?datasource_id=' + this.pubmedID



    // console.log(api_url)
    this.fetchJson(api_url)
      .subscribe(
        jsonData => {
          let api_data = jsonData['data'];
          // console.log(jsonData);

          for (let i = 0; i < api_data.length; i++ )
            {
              let linkName = "p3dbid_" + api_data[i].p3db_id;
              api_data[i]['link'] = linkName;
            }

          this.PUBMED_DATA = api_data;

          this.PUBMED_DATA.forEach((item) => {
            // item.isPresentinDatabase = item['Phosphorylation Protein(Yes/No)']
            item.batchSearch = 1;
            item.isSelected = false;
          });
          // console.log(this.PUBMED_DATA)
        },
        error => {
          this.requestError = error;
        }
      );
  }

  fetchJson(filename: string): Observable<any> {
    let headers = new HttpHeaders();
    const options = {
      responseType: 'json' as const,
    };

    return this.http.get(filename, options).pipe(
      map((file) => {
        return file;
      }),
      catchError((error) => {
        console.error('Error fetching JSON:', error);
        this.requestError = 'An error occurred while fetching data';
        return throwError(() => error);
      })
    );
  }

onBackClick()
{
    const routeToNavigate = '/countSummary'; 
    this.router.navigate([routeToNavigate]);
}

handleP3DBID(obj){
   
  this.router.navigate(["/p3dbid_"+obj.val]);

}

 
}

