//by ziting
import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as Papa from 'papaparse';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-ortholog-conservation',
  templateUrl: './ortholog-conservation.component.html',
  styleUrls: ['./ortholog-conservation.component.css']
})
export class OrthologConservationComponent implements OnInit {

  constructor(private http: HttpClient) { }
  ELEMENT_DATA;
  csvData: any;

  ngOnInit(): void {
    //this.loadCsv();
    this.getData();
  }

  getData(){
    forkJoin([
      this.fetchJson('https://p3db.ddns.net:5004/api/get_otholog8k/'),
    ]).subscribe(result => {
        console.log("fetch",result[0]['data']);
        this.dealInfo(result[0]['data']);
      });
  }

  fetchJson(filename){
    let headers = new HttpHeaders();

    const options: {
      headers?: HttpHeaders;
      observe?: 'body';
      params?: HttpParams;
      reportProgress?: boolean;
      responseType: 'json';
      withCredentials?: boolean;
    } = {
      responseType: 'json',
    };

    return this.http.get(filename, options).pipe(
      map((file) => {
        return file;
      })
    );
  }

  getCsvData(filePath: string): Observable<any> {
    return this.http.get(filePath, { responseType: 'text' }).pipe(
      map(csvData => {
        let parsedData: any;
        Papa.parse(csvData, {
          header: true,
          complete: (result) => {
            parsedData = result.data;
          }
        });
        return parsedData;
      })
    );
  }

  loadCsv(): void {
    this.getCsvData('assets/Supplemental Table 2_Chunhui_v2.csv').subscribe(data => {
      this.csvData = data;
      console.log(this.csvData);
      this.dealInfo(this.csvData);
    });
  }

  dealInfo(data){
    console.log(data);
    this.ELEMENT_DATA = {
      //removed  'go_ids','go_terms
      name: ['name','uniprot_ids','uniprot_orthogs','conservation_value','conservation_value_num','conserved_or_not','conserved_phosites','matched_ids','new_location_msa','order_number','pep_count','peptide_phosites','rice_msu_id','rice_rap_id','sequence','source','soybean_locus_id'],
      display: ["Name",'Uniprot IDs','Uniprot Orthogs','Conservation Value','Conservation Value Number','Conserved Or Not','Conserved Phosites','Matched_IDs','New Location MSA','Order Number','Pep Count','Peptide Phosites','Rice MSU ID','Rice RAP ID','Sequence','Source','Soybean Locus ID'],
      data:data,
    };
  }

  // this.ELEMENT_DATA = {
  //   name: ['name','conservation_value','conservation_value_num','conserved_or_not','conserved_phosites','go_ids','go_terms','matched_ids','new_location_msa','order_number','pep_count','peptide_phosites','rice_msu_id','rice_rap_id','sequence','similar_peptide_list1','similar_peptide_list2','similar_value_list1','similar_value_list2','source','soybean_locus_id','uniprot_ids','uniprot_orthogs'],
  //   display: ["Name",'Conservation Value','Conservation Value Number','Conserved Or Not','Conserved Phosites','GO_IDS','GO-Terms','Matched_IDS','New Location MSA','Order Number','Pep Count','Peptide Phosites','Rice MSU ID','Rice RAP ID','Sequence','Similar Peptide List1','Similar Peptide List2','Similar Value List1','Similar Value List2','Source','Soybean Locus ID','Uniprot IDS','Uniprot Orthogs'],
  //   data:data,
  // };
 

}