<mat-card id="component-container" >
    <mat-card>
        <h2 id="title">Ortholog Conservation</h2>
    </mat-card>
    
    <mat-card id="title-container" style="margin-top: 10px; overflow-x: scroll;">
        <pre style="overflow-x:hidden;">
Note:									
Uniprot ID: these are Arabidopsis Uniprot ID									
Peptide: the corresponding peptides with phosphorylation 									
Pho site on peptide: the location of phosphorylated amino aicids on the peptide (start with 1 not 0)									
Pho site on full sequence: the location of phosphorylated amino aicids on the full protein sequence (start with 1 not 0)									
Locus ID: the corresponding locus id of each Uniprot ID.									
Orthogs in Soybean/Rice/Medicago/Zeamays/Lotus: the orthologs either in Soybean/Rice/Medicago/Zeamays/Lotus. 									
Conserved Pho sites: all the conserved phosphorylation sites (after MSA alignment). 									
Pho sites on MSA file: after MSA alignment, the phosphorylation sites on the full protein sequence can be changed, Pho sites on MSA file means new phosphorylation sites after MSA."									
Conservation Value: how many orthologs with phosphorylation on the same positition.					
pep_len: the length of the peptide.
        </pre>
        <div *ngIf="!ELEMENT_DATA">
            <p style="text-align: center; margin: 20px 0">Fetching orthology data...</p>
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <app-table [tableData]="ELEMENT_DATA"></app-table>
    </mat-card>
</mat-card>