<mat-card class="container-card">
    <mat-card class="background-card">
        <div style="padding-bottom: 10px;">
            <mat-card class="heading">
                <h1 id="title"> KiC Assay</h1>
            </mat-card>
        </div>
        <mat-card  id="title-container" style="margin-top: 10px;">
            <div *ngIf="!ELEMENT_DATA && !requestError">
                <p style="text-align: center; margin: 20px 0">Fetching summary data...</p>
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
          
            <div *ngIf="requestError">
                <p style="text-align: center; margin: 20px 0"><b>{{requestError}}.</b></p>
                <p style="text-align: center">Please try refreshing the page.</p>
            </div>
            
            <app-table *ngIf="ELEMENT_DATA && !requestError" [tableData]="ELEMENT_DATA"></app-table>
            
        </mat-card>
    </mat-card>
    

</mat-card>