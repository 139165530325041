<div class="home_container">
    <div class="introduction">
        <div class="header">
            <h1 style="color: white; text-shadow: 0px 4px 4px rgb(0 0 0 /80%);">Plant Protein Phosphorylation Database</h1>
            <p style="text-shadow: 0px 4px 4px rgb(0 0 0 /80%);">V4.1</p>
        </div>
        <p style="text-shadow: 0px 4px 4px rgb(0 0 0 /100%);">P3DB version 4.1 hosts protein phosphorylation data for 45 species from ~260 in-vivo proteomic studies , containing ~57,000 phosphoproteins, harboring ~220,000 phosphosites.</p>
    </div>

    <div class="keytools_box">
        <div class="box_container" style="margin-top: 0.5%;">
            <mat-card class="box one">
                <mat-card-title routerLink="/ptmPeptides" class="tool_head">
                    <strong>PTM Search</strong>
                </mat-card-title>

                <small class="tool_content">
                    The general search for any phosphorylation peptides and sites
                </small>

            </mat-card>
            <mat-card class="box two">
                <mat-card-title routerLink="/blastPtmPeptides" class="tool_head">
                    <strong>PTM Blast </strong>
                </mat-card-title>

                <small class="tool_content">
                    Use this site's PTM Peptides Blast Tool using a protein sequence as input.
                </small>
            </mat-card>
            <mat-card class="box three">
                <mat-card-title routerLink="/kicnetwork" class="tool_head">
                    <strong>Kinase-Client Assay </strong>
                </mat-card-title>
                <small class="tool_content">
                    Kinase-Client (KiC) assay is a MS-based method developed by Thelen Group
                    for identifying kinase-client interactions and quantifying kinase activity.
                </small>
            </mat-card>
        </div>
    </div>

    <!-- SECOND ROW -->
    <div class="keytools_box">
        <div class="box_container">
            <mat-card class="box four">
                <mat-card-title routerLink="/countSummary" class="tool_head">
                    <strong>Datasets</strong>
                </mat-card-title>

                <small class="tool_content">
                    View a list of phosphorylation studies associated with a certain organism. 
                </small>

            </mat-card>


            <mat-card class="box five">
                <mat-card-title routerLink="/kinase" class="tool_head">
                    <strong>Kinase </strong>
                </mat-card-title>

                <small class="tool_content">
                    Browse a database of all known plant-based kinases represented by a TAIR identifier.
                </small>
            </mat-card>

            <mat-card class="box six">
                <mat-card-title routerLink="/phylogenetic-tree" class="tool_head">
                    <strong>Organisms </strong>
                </mat-card-title>

                <small class="tool_content">
                    Explore a visual representation of the evolutionary relationships among the organisms used in this site. 
                </small>
            </mat-card>
        </div>

    </div>
</div>
