<mat-card class="base">
    <div style="margin-top: 1%;">
        <mat-tab-group [@.disabled]="true">
            <mat-tab>
                <ng-template mat-tab-label>
                    Phospho peptides summary
                </ng-template>
                <app-count-summary></app-count-summary>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    KiC Assay summary
                </ng-template>
                <app-kic-papers></app-kic-papers>
            </mat-tab>   
        </mat-tab-group>
    </div>
</mat-card>